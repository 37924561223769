.ledgerWrap {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto 30px;

    table {
        margin: 16px auto;

        thead {
            tr {
                th.headingOne {
                    padding: 3px 0;
                    border: 0px solid #7b7c7f;
                    border-top: 0px solid #fff;
                }
            }
        }

        .secHeading {
            th {
                text-align: center;
                background-color: #2a363b;
                color: #fff;
                padding: 15px;
                font-weight: bold;
                letter-spacing: normal;
                text-transform: uppercase;
            }
        }

        tr {

            td,
            th {
                border: 5px solid #E9E9E9;
            }
        }

        tbody {
            tr {
                td {
                    font-size: 12px;
                    font-weight: 500;
                    padding: 13px 15px;
                    letter-spacing: normal;
                    background-color: #fff;
                    // text-align: center;

                    a{
                        color:   rgb(0 123 255);
                    }
                }
            }
        }
    }
}

.casinoDetailsWrap {
    h6 {
        margin: 0;
        padding: 3px;
        border-top: 1px solid #ddd;
        border-left: 3px solid #ddd;
        border-right: 3px solid #ddd;
    }

    table {
        white-space: nowrap;
        background-color: #fff;

        tr.secHeading {
            th {
                // padding: 15px;
                font-size: 14px;
                font-weight: 700;
                line-height: 1.4;
                letter-spacing: 1px;
                text-align: center;
                color: #fff;
                padding: 14px 5px;
                text-transform: uppercase;
                background: #000000;
                border: 5px solid #E9E9E9;
            }
        }

        tbody {
            tr {
                td {
                    // font-weight: 700; 
                    font-size: 12px;
                    padding: 13px;
                    line-height: 1.5;
                    // font-size: 14px;
                    letter-spacing: 0.3px;
                    font-weight: 500;
                    // text-align: center;
                    // border: 1px solid #3d8282;
                    border: 5px solid #E9E9E9;
                    // border: 5px solid red;
                }
            }
        }
    }
}

.ledgerDetailsWrap {
    .headingOne {
        font-size: 14px;
        letter-spacing: 1px;
        padding: 5px 0;
        background: #52b5e1;
        font-weight: 900;
        border-bottom: 1px solid #fff;
    }

    h6 {
        margin: 0;
    }

    .boxTitle {
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        padding: 4px;
        text-align: center;
        font-weight: bold;
        background: #3c7c88;
        letter-spacing: normal;
        font-family: "Roboto", sans-serif;
    }

    .boxResult {
        font-size: 14px;
        color: #7b7c7f;
        padding: 4px 0;
        font-weight: 600;
        text-align: center;
        background-color: #fff;
        line-height: 20px;
        font-family: "Roboto", sans-serif;
    }

    .red {
        color: red;
    }

    .blue {
        color: #5199e6;
    }

    table {
        white-space: nowrap;
        background-color: #fff;

        thead {
            tr {
                th {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 1.4;
                    letter-spacing: 1px;
                    text-align: center;
                    color: #fff;
                    padding: 2px;
                    text-transform: capitalize;
                    background: #3c7c88;
                    // background:  linear-gradient(to right, #1e0508 0, #513539 51%, #120608 100%);
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 11px 8px;
                    line-height: 1.5;
                    font-size: 14px;
                    letter-spacing: 0.3px;
                    font-weight: 500;
                }
            }
        }

        // .secHeading {
        //     th {
        //         text-align: center;
        //         background-color: #2A363B;
        //         color: #fff;
        //         padding: 15px;
        //         font-weight: bold;
        //         letter-spacing: 1px;
        //         text-transform: uppercase;
        //     }
        // }

        tr {

            td,
            th {
                font-weight: 700;
                border: 1px solid #3d8282;
            }
            th { 
                border: 2px solid #999;
            }

        }
    }

    .moreDetail {
        margin: 30px 0px 0px;
    }
}

@media (min-width:767px) {
    section.casinoDetailsWrap {
        max-width: 1100px;
        margin: auto;
    }
}

@media (max-width:767px) {
    .ledgerWrap {
        table {
            margin: 0;
            white-space: nowrap;

            .secHeading {
                th {
                    white-space: nowrap;
                    font-size: 13px;
                    padding: 14px 5px;
                }
            }

            tbody {
                tr {
                    td {
                        padding: 12px 10px;
                        // white-space: nowrap;
                    }
                }
            }
        }

        a.backBtn {
            margin: 30px auto 0;
        }
    }
}