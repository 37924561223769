.bgBack {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    //  background-image: linear-gradient(#8c9088 0, #8c9088 100%);
    // background-color: #3a91e0;
    background: linear-gradient(#3c7c88 0,#000 100%);
    
    h6 {
        color: #fff;
        font-size: 18px;
        line-height: 28px;
        padding: 10px 0 17px;
        font-weight: 800;
        text-align: center;
        margin: 0 0 04px;
        letter-spacing: normal;
        
        font-family: 'Roboto', sans-serif;
    }

    .loginOuter {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .loginLeft {
            width: 50%;

            img {
                width: 100%;
            }
        }

        .loginRight {
            width: 50%;
            display: flex;
            align-items: center;
            margin: 50px 0 0;

            .formcontent {
                width: 100%;
            }
        }
    }

    form {
        width: 100%;
        max-width: 464px;
        margin: auto;

        .form-group {
            input {
                height: 41.6px;
                margin: 0 0 13px;
                padding: 6px 12px;
                border-radius: 0;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                border: 1px solid transparent;

                &:active,
                &:focus,
                &:after {
                    border-color: #80bdff;
                    box-shadow: 0 0 0 0.2rem #007bff40;
                }

                &::placeholder {
                    font-size: 14px;
                    color: #6c757d;
                    font-weight: 600;
                }

                &:-ms-input-placeholder {
                    font-size: 14px;
                    color: #6c757d;
                    font-weight: 600;
                }

                &::-ms-input-placeholder {
                    font-size: 14px;
                    color: #6c757d;
                    font-weight: 600;
                }
            }
        }
        .form-button{
            button[type="submit"]{
                
                &:active,
                &:hover{
                    color: #fff;
                    background-color: #d1272a;    
                }
            }
        }

        .login_btn {
            width: 100%;
            color: #fff;
            font-weight: 800;
            font-size: 14px;
            line-height: 20px;
            height: 36px;
            padding: 0px 0;
            margin: 0;
            border-radius: 0px;
            background-color: #d1272a;
            border: 0px solid #d1272a;
            font-family: 'Roboto', sans-serif;
            //  background: linear-gradient(-180deg, #315195 0%, #14213d 100%);

            i {
                margin: 0 0 0 3px;
            }

            .fa-spin {
                font-size: 19px;
            }
        }
    }

    .privacyPolicy {
        width: 100%;
        max-width: 280px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 0 0 8px;
        margin: 15px auto 0;

        li {
            color: #ffffff;

            a {
                font-size: 14px;
                line-height: 20px;
                color: #000;
                padding: 0 05px;
                font-weight: 500;
                letter-spacing: normal;
                text-decoration: underline;

                // font-family: 'robotoMedium';
                &:hover {
                    text-decoration: none; 
                    color: #0a58ca; 
                }
            }
        }
    }

    .errorMes {
        opacity: 0;
        top: -20px;
        position: absolute;
        padding: 0;
        background: #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 0 15px -5px #000;
        transition: all 0.3s ease-in-out;

        i {
            background: #ff5500;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            text-align: center;
            line-height: 20px;
            color: #fff;
            margin: 0 5px 0 0;
        }

        span {
            max-width: 180px;
            display: inline-block;
            font-size: 12px;
            font-weight: 700;
            line-height: 1;
        }
    }

    .errorMes.active {
        opacity: 1;
        top: 20px;
        padding: 10px;
        transition: all 0.2s ease-in-out;
    }


    @media (min-width: 768px) {}

    @media (max-width: 767px) {


        .loginOuter {
            // place-self: flex-start;

            .loginLeft {
                width: 100%;
                margin: 0px auto 40px;
            }

            .loginRight {
                width: 100%;
                margin: 0 auto 0;
                // border: 1px solid red;
            }
        }

        form {
            width: 100%;
            padding: 0 30px;
        }
    }
}