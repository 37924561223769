$white: #fff;
$black: #000;
$primary: #0A92A5;
$secondry: #087989;
$active: #2f3e46;
$lightGray: #e9e9e9;
$green: #279532;
$danger: #bb1919;
$yellow: #ffeb3b;

$pink: #faa8ba;
$pink75: #faa8babf;
$pink50: rgb(250 168 186 / 83%);

$sky: #72bbef;
$sky75: #72bbefbf;
$sky50: #72bbef80;

$light-bg: #f1f5f8;
$theam1__bg: #0A92A5;
$theam2__bg: #0A92A5;
$theam2__bg85: #0A92A5;
$theam2__bg86: #3c7c88;



$tableTh: #52796f;
$primaryBg: #52796f;
$primaryBtn: #ee892d;
$secondryBg: #2f3e46;
$light-gray: #eee;

$gradientBgOne: linear-gradient(#2f3e46, #52796f);
$gradient-bg01: linear-gradient(#2f3e46, #52796f);
$bg_gradient02: linear-gradient(to bottom right, #2f3e46 0%, #52796f 100%);
$font-family: "Roboto Condensed", sans-serif;
$font-family1: "Roboto";

// table {
//   tr {

//     th,
//     td {
//       font-family: $font-family !important;
//     }
//   }
// }

.pink {
  // background-color: $pink50;
}

.pink01 {
  // background-color: $pink75;
}

.pink02 {
  // background-color: $pink;
}

.sky {
  // background-color: #72bbef;
}

.sky01 {
  background-color: $sky75;
}

.sky02 {
  background-color: $sky50;
}

.lay {
  // background-color: #76c6c6;
}

.back {
  // background-color: $pink;
}

.BgBack {
  background: linear-gradient(115deg,#5fa8eee6,#90c3f4e6 52.26%,#baddffe6 101.35%);
  // background-color: #72bbef;
}

.BgLay {
  background: linear-gradient(245deg,#f092ade6 -.45%,#fcaac1e6 48.62%,#ffd0dee6 99.78%);
  // background-color: $pink;
  // background-color: #F6B771;
  
}

.BgWhite {
  background-color: #fff;
}

.white {
  color: $light-bg;
}

.red {
  color: #ef4444 !important;
}

.blue {
  color: rgb(82 181 225) !important;
}


.white1 {
  background-color: white;
}

.light-gray {
  background-color: $light-gray;
}


.bgYellow {
  background-color: $yellow !important;
}

.suspended {
  position: absolute;
  left: unset;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(18, 17, 17, 0.76);
  text-align: center;
  color: #fff;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  width: 50%;
  font-weight: 700;
}

@media (max-width:767px) {
  .suspended {
    font-size: 15px;
  }
}


/* */
$bg-gradient01: #3c7c88;
// $bg_gradient02: linear-gradient(to bottom right, #2f3e46 0%, #52796f 100%);
$bg_gradient02: linear-gradient(to right, rgb(30, 5, 8) 0px, rgb(81, 53, 57) 51%, rgb(18, 6, 8) 100%);

/* */
input {

  &:active,
  &:hover,
  &:target,
  &:focus {
    outline: none;
    border: 1px solid transparent;
  }
}

img {
  width: auto;
  max-width: 100%;
  height: auto;
}

.marquee {
  font-size: 13px;
  font-weight: 700;
  padding: 5px 0 0;
  text-transform: capitalize;

}

.matchDatailsWrap {
  .marquee {
    background-color: #000000;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    margin: 0;
    padding: 0px 0;
  }
}

.matchDatailsWrap,
.ledgerDetailsWrap,
.statementWrap {
  width: 100%;
  max-width: 1170px;
  margin: auto;
}

/* ================================== COMMAN CSS FLEX START ================================ */
.Fw {
  display: flex;
  flex-wrap: wrap;
}

.Fwc {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.Fwss{ 
  display: flex;
  flex-wrap: wrap;
  align-items: self-start;
}
.Fwcsb {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.Fwsb {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Fwcc {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.Fwcr {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
}

/* MIXING CSS */
@mixin fw {
  display: flex;
  flex-wrap: wrap;
}

@mixin fwc {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@mixin fwss {
  display: flex;
  flex-wrap: wrap;
  align-items: self-start;
}

@mixin fwcsb {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@mixin paragraph10 {
  font-size: 10px;
  line-height: 1.5em;
  border-radius: 0;
  font-family: $font-family;
}

@mixin paragraph12 {
  font-size: 12px;
  line-height: 1em;
  border-radius: 0;
  font-family: $font-family;
}
@mixin paragraph013 {
  font-size: 13px;
  line-height: 1em;
  border-radius: 0;
  font-family: $font-family;
}

@mixin paragraph13 {
  font-size: 12px;
  line-height: 16px;
  border-radius: 0;
  font-family: $font-family;
}

@mixin paragraph14 {
  font-size: 14px !important;
  line-height: 1em;
  border-radius: 0;
  font-family: $font-family;
}
@mixin paragraph016 {
  font-size: 14px;
  line-height: 1em;
  border-radius: 0;
  font-family: $font-family;
}

@mixin paragraph15 {
  font-size: 15px;
  line-height: 1em;
  border-radius: 0;
  font-family: $font-family;
}

@mixin paragraph16 {
  font-size: 16px;
  line-height: 1em;
  font-family: $font-family;
}
@mixin paragraph160 {
  font-size: 16px;
  line-height: 1em;
  font-family: $font-family1;
}



/* ================================== COMMAN CSS FLEX END================================ */
/* ================================== COMMAN CSS TABLE START================================ */
table {
  width: 100%;
}

/* ================================== COMMAN CSS TABLE START================================ */

.bg-gradient01 {
  background-color: $bg-gradient01;
}

.bg-gradient02 {
  background-image: $bg-gradient02;
}

.bg-white {
  background-color: white;
}


/**/

input {
  width: 100%;
  border-radius: 2px;
  display: block;
}

.headingOne {
  padding: 7px 0;
  font-size: 13px;
  line-height: 19.5px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  color: $white;
  // background-color: $primary;
  background-color: #3c7c88;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.headingTwo {
  padding: 7px 0;
  font-size: 16px;
  line-height: 1;
  text-transform: capitalize;
  letter-spacing: 0.3px;
  color: $white;
  background-color: #dc143c;
  text-align: center;
}

.backBtn {
  width: 100%;
  display: block;
  padding: 4px 0;
  font-size: 13px;
  font-weight: 700;
  line-height: 19.5px;
  text-transform: capitalize;
  letter-spacing: 1.3px;
  color: $white;
  text-transform: uppercase;
  // background-color: $secondry;
  background-color: #3c7c88;
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.backBtn:hover {
  color: #fff;
}

.show {
  display: block;
}

.hide {
  display: none;
}

ul.pagination {
  display: flex;
  align-items: center;
  // justify-content: center;

  
  li.next {
    a {
      color: #337ab7;
    }
  }

  li.previous {
    a {
      color: #777;
    }
  }

  li.next,
  li.previous {
    a {
      color: #ddd;
      display: block;
      // height: 32px;
      line-height: 24px;
      padding: 3px 8px; 
      border-radius: 0px;
      background-color: transparent;
      border: 1px solid gray; 
      margin: 0 0px;
      font-weight: 400;
      font-size: 16px;
    }
  }



  li.page-item {
    margin: 0 3px;

    a {
      
      font-weight: 700;
      padding: 3px 8px; 
      font-size: 16px;
      display: block;
      // width: 32px;
      // height: 32px;
      line-height: 24px;
      text-align: center;
      border-radius: 0px;
      font-weight: 600;
      color: #000; 
      background-color: transparent;
      border: 1px solid grey;
    }
  }

  .page-item.active {
    a {
      color: #fff;
      background-color: #337ab7;
      border: 1px solid #337ab7;
    }
  }

}

.tableScroll {
  overflow-x: auto;
}

/*=======================================================================
                 CUSTOM BOX SIZE DEFINED kirti css
========================================================================= */

.B_sz1 {
  width: 12%;
  min-width: 12%;
  max-width: 12%;
}

.B_sz2 {
  width: 15%;
  min-width: 15%;
  max-width: 15%;
}

.B_sz3 {
  width: 20%;
  min-width: 20%;
  max-width: 20%;
}

.B_sz40 {
  width: 40%;
  min-width: 40%;
  max-width: 40%;
}

.B_sz40 {
  width: 40%;
  min-width: 40%;
  max-width: 40%;
}

.B_sz33 {
  width: 33%;
  min-width: 33%;
  max-width: 33%;
  height: 40px;
}
.B_sz30 {
  width: 30%;
  min-width: 30%;
  max-width: 30%;
}

.B_sz4 {
  width: 28%;
  min-width: 28%;
  max-width: 28%;
}

.B_sz5 {
  width: 52%;
  min-width: 52%;
  max-width: 52%;
}

.B_sz5 {
  width: 52%;
  min-width: 52%;
  max-width: 52%;
}

.B_sz6 {
  width: 60%;
  min-width: 60%;
  max-width: 60%;
  height: 45px;
}
.B_sz08 {
  width: 80%;
  min-width: 80%;
  max-width: 80%;
  height: 45px;
}

.B_sz06 {
  width: 60%;
  min-width: 60%;
  max-width: 60%;
  height: 39px;
}
.B_sz006 {
  width: 60%;
  min-width: 60%;
  max-width: 60%;
  height: 45px;
}
.B_sz008 {
  width: 80%;
  min-width: 80%;
  max-width: 80%;
  height: 35px;
}
.B_sz70 {
  width: 67%;
  min-width: 67%;
  max-width: 67%;
  height: 40px;
}

.B_sz10 {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

/* */
// body .Toastify .Toastify__toast-container{
//     -webkit-transform: unset !important; 
//   }
//   .Toastify__toast-body{
//     display: unset !important;


//   }
//   body .Toastify .Toastify__toast, 
//   .customAdd .Toastify__toast {
//     padding: 15px;
//     line-height: 1;
//     // border-radius: 5px;
//     // color: #802630;
//     color: white;
//     font-weight: 600;
//     // position: fixed;
//     width: 130%;
//     height:10rem;
//     left: 2%;
//     top: 1%;
//     // display: flex;
//     // justify-content: space-between;
//     // align-items: center;
//     top: 10px;
//     line-break: normal;
//     text-align: center;
//     text-transform: capitalize;
//   }

//   body .Toastify .Toastify__toast.Toastify__toast-theme--light{
//     background: #f17f91;
//   }
//   body .Toastify .Toastify__toast.Toastify__toast--success{
//     // background: #d4f0da;
//     background: #bcdef9;
//     line-break: normal;
//   }
//   .Toastify__toast-icon {
//     width: 18%!important;
//     margin-left: 140px ;
//     margin-bottom: 20px;

//   }
//   .Toastify__toast--error{
//     .Toastify__toast-icon{
//       display: none;
//     }
//   }
//   .Toastify__close-button .Toastify__close-button--light{
//     display: none;
//   }

//   body .Toastify  .Toastify__toast .Toastify__close-button--light,
//   .customAdd .Toastify__toast .Toastify__close-button--light {
//     opacity: 1;
//     top: 10px;
//     position: relative;

//   }
//   body .Toastify .Toastify__toast .Toastify__progress-bar,
//   .customAdd .Toastify__toast .Toastify__progress-bar {
//     opacity: 0 !important;
//     line-break: normal;
//     display: flex;
//     justify-content: center;
//     text-align: center;

//   }
//   body.Toastify__toast-icon .Toastify--animate-icon .Toastify__zoom-enter{
//    display: none !important;
//   }
//   body .Toastify  .Toastify__close-button>svg,
//   .customAdd .Toastify__close-button>svg {
//     fill: #7b686a;
//   } 
body .Toastify .Toastify__toast-container {
  -webkit-transform: unset !important;
}

body .Toastify .Toastify__toast,
.customAdd .Toastify__toast {
  padding: 15px;
  line-height: 1;
  border-radius: 5px;
  color: #802630;
  font-weight: 600;
  // position: fixed;
  width: 96%;
  left: 2%;
  top: 1%;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  top: 10px;
  text-transform: capitalize;
}

body .Toastify .Toastify__toast.Toastify__toast-theme--light {
  background: #fbdbe0;
}

body .Toastify .Toastify__toast.Toastify__toast--success {
  background: #d4f0da;
}

body .Toastify .Toastify__toast .Toastify__close-button--light,
.customAdd .Toastify__toast .Toastify__close-button--light {
  opacity: 1;
  top: 10px;
  position: relative;
}

body .Toastify .Toastify__toast .Toastify__progress-bar,
.customAdd .Toastify__toast .Toastify__progress-bar {
  opacity: 0 !important;
}

body .Toastify .Toastify__close-button>svg,
.customAdd .Toastify__close-button>svg {
  fill: #7b686a;
}

.casinoresult_modal {
  .modal-content {
    border-radius: 0;

    .modal-header {
      background-color: #3c7c88;
      padding: 8px 12px;
      color: #fff;
      border-radius: 0;

      .modal-title {
        font-size: 16px;
        // font-weight: bold;
        margin-bottom: 0;
        line-height: 19px;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
      }

      button.btn-close {
        font-size: 12px;
        font-weight: 700;
        filter: invert(1);
        opacity: 1;
        text-shadow: 0 1px 0 #fff;
        background: transparent;
        &:after {
          display: block;
          content: "\f00d";
          // background-image: url(../../public/images/cross.png);
          font-family: "Font Awesome 5 Free";
          width: 30px;
          height: 30px;
          position: absolute;
          right: 5px;
          top: 8px;
          font-weight: 900;
          line-height: 1;
          text-align: right;
          background-repeat: no-repeat;
          background-position: right;
          filter: inherit;
          background-size: cover;
        }
      }
    }
  }
  .paddingzero.modal-body {
    padding: 0;
  }
  .modal-body {
    padding: 16px;

    h6 {
      @include paragraph16;
      text-align: right;
      line-height: 1.5em;
      margin: 0;
      b {}
    }

    .comman_cricket_score {}
    .market-title {
      font-size: 13px;
      line-height: 1.5em;
      padding: 4px 8px;
      color: $white;
      background-color: #4b0a08d9;
      // background-color: $theam2__bg;
    }

    .cricket-match-result {
      .winner {
        @include paragraph12;
        line-height: 1.5em;

        .text-success {
          color: $green;
        }
      }

      .winner-board {
        @include paragraph12;
        line-height: 1.5em;
        padding: 10px;
        box-shadow: 0 0 5px #aaa;
        background-color: $light-bg;
      }
    }

    table {
      width: 100%;
      color: #212529;

      th,
      td {
        @include paragraph12;
        line-height: 1.5em;
        min-width: 20px;
        border: 1px solid #aaa;
        padding: 3px 2px;
        vertical-align: middle;
        text-align: center;
      }

      th:first-child {
        min-width: 55px;
        word-break: break-all;
      }
    }

    .result-image {
      img {}
    }

    /*1*/
    .race_result_box {
      width: 205px;
      position: relative;
      z-index: 10;

      div {
        display: flex;
        flex-wrap: wrap;

        img {
          width: 25px;
          margin-left: 2px;
          margin-right: 2px;
        }

        .result-image.k-image {
          position: absolute;
          right: -30px;
        }

        .winner-label {
          position: absolute;
          right: -75px;
          top: 0;
          bottom: unset;
          color: green;

          i {
            font-size: 26px;
            -webkit-animation: winnerbox 2s infinite;
            animation: winnerbox 1.5s infinite;
            border-radius: 50%;
          }
        }
      }

      .video-winner-text {
        color: #000;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 14px;
        width: 30px;
        border: 1px solid yellow;
        padding: 4px 8px;
        z-index: -1;
        background-color: #efeded;
      }
    }

    /*2*/
    .oepn_teen_result {
      @include paragraph12;
      box-shadow: 0 0 2px;
      margin-bottom: 5px;
      padding-top: 5px;
      padding-bottom: 5px;

      .winner-title {
        h4 {
          @include paragraph14;
          font-weight: 900;
          line-height: 1.2em;
        }
      }

      .resultimg {
        display: flex;

        img {
          margin: 0 2px;
          width: 36px;
        }
      }

      .winner-label {
        i {
          color: green;
          font-size: 26px;
          box-shadow: 0 0 0 #4b0a08;
          -webkit-animation: winnerbox 2s infinite;
          animation: winnerbox 1.5s infinite;
          border-radius: 50%;
        }
      }
    }

    /*3*/
    .InstantWorliResult {
      .result_image {
        img {
          width: 36px;
          margin: 1px;
        }
      }
    }

    /*4*/
    .CasinoFont_bet_popup {}

    /* */
    .Comman_pop5,
    .dragon_tiger_result {
      img {
        display: inline-block;
        width: 35px;
        height: auto;
        margin-right: 3px;
      }

      .winner-board {
        @include paragraph16; 
        line-height: 24px;
        font-weight: 500;
        padding: 10px;
        // box-shadow: 0 0 5px #aaa;
        // background-color: $light-bg;

        .text-success {
          color: $green;
        }
      }
    }

    /*5*/
    .casino_meater {
      img {
        margin: 0 0 2px;
      }

      .box01 {
        height: 100%;
      }

      h4 {
        @include paragraph16;
      }
    }
  }
}

.suspends {
  position: relative;

  &::after {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(18, 17, 17, 0.80);
    // content: "\f023";
    // font-family: "Font Awesome 5 Free";
    content: url("../../public/images/suspendlock.png");
    contain-intrinsic-height: 50%;
    contain-intrinsic-width: 50%;
    font-weight: 900;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
  }
}
.suspendscasino {
  position: relative;

  &::after {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(18, 17, 17, 0.36);
    // content: "\f023";
    // font-family: "Font Awesome 5 Free";
    content: url("../../public/images/suspendlock.png");
    contain-intrinsic-height: 50%;
    contain-intrinsic-width: 50%;
    font-weight: 900;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
  }
}


.suspendsText {
  position: relative;

  &::after {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #000000b3;
    content: "suspends";
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-family;
    font-size: 16px;
    color: #ff3c3c;
    text-transform: uppercase;
  }
}

.book_profit {
  color: #000;
  font-size: 10px;
}
.title{
  padding-bottom: 2px;
}

